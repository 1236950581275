<div class="d-flex justify-content-xl-end justify-content-sm-center">
  <a title="Download Resume" target="_blank" class="btn btn-dark" style="text-decoration: none; color: white; margin: 10px;" href="/assets/files/resume/Nicholas_Occhipinti_Resume.pdf"><mat-icon style="position: relative; top: 5px; bottom: 5px;" aria-hidden="false" aria-label="Example home icon">download</mat-icon>
    <span class="button-text">Download Resume</span>
  </a>
</div>
<div>
  <h1 class="text-center" style="color: white;font-family: monospace">My Resume</h1>
  <h3 class="text-center" style="color: white;font-family: monospace">Click each section below to expand resume.</h3>
</div>
<div style="padding: 0 5%;">
<mat-accordion id="resume_accordian" multi>

  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Experience</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngFor="let e of resume.experience">
      <mat-card>
        <mat-card-content>
          <div class="row align-items-center">

            <img style="padding:10px;" src="{{e.image.imgPath}}" alt="..." class="resume-image col-sm-4 col-lg-2">
            <div class="col-sm-8 col-lg-10">
              <strong>{{e.name}}</strong> - {{e.location}}
              <br>
              <strong>{{e.role}}</strong>
              <br>
              {{e.startDate}} - {{e.endDate}}
              <br>
              <br>
              <ul>
                <li *ngFor="let desc of e.desc">{{desc}}</li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>Education</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let ed of resume.education">
    <mat-card>
      <mat-card-content>
        <div class="row align-items-center">
          <img style="padding:10px;" src="{{ed.image.imgPath}}" alt="..." class="resume-image col-sm-4 col-lg-2 ">
          <div class="col-sm-8 col-lg-10">
            <strong>{{ed.university}}</strong>, {{ed.location}}
            <br>
            {{ed.degree}} - {{ed.major}}
            <br>
            {{ed.startDate}} - {{ed.endDate}}
            <div *ngIf="ed.gpa"> {{ed.gpa}} GPA</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>Projects</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let r of resume.resumeProject">
    <mat-card>
      <mat-card-content>
        <div>
          <div><strong>{{r.name}}</strong> </div>
          <div><strong *ngIf="r.url"> <a href="{{r.url.link}}" target="_blank">{{r.url.name}}</a></strong></div>
        </div>
        <br>
        <ul>
          <li *ngFor="let desc of r.desc">{{desc}}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>Skills</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div style="display: flex; justify-content: space-around;flex-wrap: wrap">
  <mat-card class="skill_card">
    <h3 style="text-align: center"><strong>GIS</strong></h3>
    <mat-card-content>
      <ul>
        <li>ArcGIS
          <ul>
            <li>ArcGIS Pro</li>
            <li>ArcGIS Server</li>
            <li>Portal for ArcGIS</li>
            <li>ArcGIS JavaScript API</li>
            <li>ESRI Utility Network</li>
            <li>Spatial Analysis</li>
          </ul>
        </li>
        <li>CAD
           <ul>
            <li>AutoCAD</li>
            <li>Revit</li>
          </ul>
        </li>
        <li>Survey
          <ul>
            <li>Trimble Survey Equipment</li>
            <li>ArcGIS Field Maps</li>
          </ul>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card class="skill_card">
    <h3 style="text-align: center"><strong>Data Science</strong></h3>
    <mat-card-content>
      <ul>
        <li>Tableau
          <ul>
            <li>Desktop</li>
            <li>Server</li>
            <li>Prep</li>
          </ul>
        </li>
        <li>Python
           <ul>
            <li>Pandas</li>
            <li>Scikit Learn</li>
            <li>Tensorflow</li>
          </ul>
        </li>
        <li>R
          <ul>
            <li>Shiny</li>
            <li>Dplyr</li>
            <li>ggplot2</li>
          </ul>
        </li>
        <li>
          Neo4J
        </li>
        <li>
          XGBoost
        </li>
        <li>
          Word2Vec
        </li>
        <li>
          Deep Learning
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card class="skill_card">
    <h3 style="text-align: center"><strong>Development</strong></h3>
    <mat-card-content>
      <ul>
        <li>Angular</li>
        <li>Python</li>
        <li>JavaScript\TypeScript</li>
        <li>SQL</li>
        <li>Source Control
          <ul>
            <li>Git</li>
            <li>GitHub</li>
            <li>SourceTree</li>
          </ul>
        </li>
        <li>Server
          <ul>
            <li>NGINX</li>
            <li>Windows Server</li>
            <li>Linux</li>
          </ul>
        </li>
        <li>Database
          <ul>
            <li>Oracle</li>
            <li>Postgres\PostGIS</li>
            <li>MongoDB</li>
          </ul>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
  </div>
</mat-expansion-panel>

</mat-accordion>


</div>
