

<div class="card" style="margin: 10px;">
  <a style="padding: 10px;" routerLink="/project/{{project.id}}">
      <img class="img-fluid" src="{{project.images[0].imgPath}}">
  </a>
  <div class="card-body">
    <h3 style="font-weight: bold;margin: 0px;" class="card-title">{{project.name}}</h3>
    <p class="card-text">{{project.caption}}</p>
  </div>
</div>
