<div class="container-fluid ">
  <div class="d-flex flex-wrap flex-row justify-content-around align-items-center">
    <div class="text-center">
      <img style="max-width: 200px; margin: 20px;" class="img-fluid" src="assets/img/59-NicholasOcchipinti7190-rev1.png" alt="picture">
      <h3 style="font-family: monospace; font-weight: bold;" class="heading">Knowledge in Development, Geographic Information Systems and Data Science</h3>
    </div>
    <div class="intro w-auto" style="">
      <p style="font-family: 'Roboto';">
      I am Nicholas Occhipinti and I have over 15 years of experience working in the field of Geographic Information Systems.
      I have worked on projects analyzing geospatial data, designing maps, developing web mapping applications, automating GIS processes and administering GIS servers and applications.
      <br><br>
      I have a Masters Degree from UNC Charlotte in Data Science and Business Analytics.  During my degree I have worked on projects involving social media analytics,
      Tableau and R Shiny dashboards, machine learning, network science using Neo4J, Deep Learning and NLP for text classification.

      My objective is to combine my knowledge of Data Science and GIS experience to solve different problems.  Some areas of interest are Network Science, Natural Language Processing and Deep Learning.
      <br><br>
      This portfolio showcases the different projects I have worked on and the different skills that I have developed throughout my career both professionally and as a student.
      You can find my resume below as well as links to my GitHub and LinkedIn pages.
      </p>
    </div>
  </div>
</div>

<div style="padding: 10px;">
  <h1 class="heading">Featured Projects</h1>
   <div class="d-flex flex-wrap justify-content-around">
    <app-project style="margin: 10px;" [project]="project" *ngFor="let project of this.projects"></app-project>
  </div>
</div>
