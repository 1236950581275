<div class="cont">
  <app-menubar></app-menubar>
  <div class="main">
    <router-outlet></router-outlet>
  </div>

  <footer>
    <section style="height: 50px;" class="d-flex flex-row justify-content-center align-items-center align-content-end">
      <a href="mailto: nicholasocchipinti@gmail.com">
        <span style="width: 24px;height: 24px; margin: 5px;"  class="iconify" data-icon="clarity:email-solid" data-inline="false"></span>
      </a>
      <a href="https://www.linkedin.com/public-profile/in/nicholas-occhipinti-46419067" target="_blank">
        <span style="width: 24px;height: 24px; margin: 5px;" id="linked-in" class="iconify" data-icon="mdi-linkedin" data-inline="false"></span>
      </a>
      <a href="https://github.com/nick-occ" target="_blank">
        <span style="width: 24px;height: 24px; margin: 5px;" class="iconify" data-icon="mdi:github" data-inline="false"></span>
      </a>
      <a href="/assets/files/resume/Nicholas_Occhipinti_Resume.pdf" target="_blank">
        <span style="width: 24px;height: 24px; margin: 5px;" class="iconify" data-icon="emojione-v1:document-with-text" data-inline="false"></span>
      </a>
    </section>
  </footer>
</div>
