<div class="container-fluid">
  <section class="row align-items-center">

      <div class="col-xl-6" id="image">
        <h1 class="heading"><b>{{project.name}}</b></h1>
        <h3 class="heading">{{project.caption}}</h3>
        <ngb-carousel class="img-carousel" [interval]="0" >
          <ng-template ngbSlide *ngFor="let img of project.images; index as i">
            <app-project-page-image class="heading" [currentImage]="img"></app-project-page-image>
          </ng-template>
        </ngb-carousel>
      </div>

      <div class="col-xl-6" id="desc">
        <h1 class="heading">Description</h1>
        <mat-card>
        <p class="content" [innerHTML]="project.desc" ></p>
      </mat-card>
        <br>
        <h1 class="heading">Skills Used</h1>
        <ul >
          <li *ngFor="let skill of project.skills" class="project-skill" ngStyle="">{{skill}}</li>
        </ul>
        <div class="d-flex justify-content-around align-items-center">
          <div *ngFor="let l of project.links">
              <a title="{{l.name}}" class="btn btn-dark" style="text-decoration: none; color: white; margin: 10px;" href="{{l.url}}" target="_blank"><mat-icon style="position: relative; top: 5px; bottom: 5px;" aria-hidden="false" aria-label="Example home icon">{{l.icon}}</mat-icon>
                <span class="button-text">  {{l.name}}</span>
              </a>
          </div>
        </div>
      </div>
  </section>
  </div>
