<!--<div style="display: flex; flex-direction: row; justify-content: space-around; flex-wrap: wrap" >-->
<!--<div *ngFor="let project of this.projects">-->
<!--  <app-project [project]=project></app-project>-->
<!--</div>-->
<!--</div>-->


  <div class="d-flex flex-wrap justify-content-around" style="height: 100%;">
    <app-project [project]="project" *ngFor="let project of this.projects"></app-project>
  </div>

